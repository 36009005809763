import { transformMockImageSize } from "@sushicorp/utils";
import { Image } from "artisn-ui-react";
import React from "react";

import Styles from "./SliderCard.styles";
import { SliderCardProps as Props } from "./SliderCard.types";
import { shouldMock } from "utils/common.utils";

const SliderCard: React.FC<Props> = props => {
  const { images, name, onClick, className } = props;
  const { imageConfig } = props;
  const [firstImage] = images;
  const { height, width } = imageConfig;

  const image = transformMockImageSize(
    firstImage,
    {
      imageHeight: height,
      imageWidth: width
    },
    shouldMock
  );

  return (
    <Styles onClick={onClick} className={`SliderCard ${className}`}>
      <div>
        {firstImage ? (
          <Image
            className="SliderCard__image"
            image={image}
            config={imageConfig}
            alt={name}
            placeholder="blur"
            errorImage="/assets/images/fallback-kobe.png"
          />
        ) : null}
      </div>
      <div className="SliderCard__content">
        <div className="SliderCard__content-bottom">
          <p className="SliderCard__content-title" data-text={name}>
            {name}
          </p>
        </div>
      </div>
    </Styles>
  );
};

SliderCard.defaultProps = {
  className: ""
};

export default SliderCard;
