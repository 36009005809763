import { Storage } from "@capacitor/storage";
import { useStores } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { useVendors } from "@sushicorp/contexts";
import { useFetchCategoriesWithProducts } from "@sushicorp/services";
import { useFetchOrderHistory, useFetchUser } from "@sushicorp/services";
import { useFetchCategories } from "@sushicorp/services";
import { goToCategory, toSentenceCaseUtility } from "@sushicorp/utils";
import { Category } from "artisn/types";
import React, { useEffect, useMemo, useState } from "react";

import { homeSliderImageConfig } from "./Home.helpers";
import { homeSliderImageConfigMobile } from "./Home.helpers";
import Styles from "./Home.styles";
import { HomeProps as Props } from "./Home.types";
import Carousel from "../Carousel/Carousel";
import HomeCards from "../HomeCards/HomeCards";
import CategorySlider from "components/global/CategorySlider/CategorySlider";
import Footer from "components/global/Footer/Footer";
import Navbar from "components/global/Navbar/Navbar";
import ProductGallery from "components/global/ProductGallery/ProductGallery";
import ProductGalleryPlaceholder from "components/global/ProductGallery/ProductGallery.placeholder";
import RepeatOrder from "components/global/RepeatOrder/RepeatOrder";
import SliderCard from "components/global/SliderCard/SliderCard";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";
import { notify } from "utils/common.utils";

import ArrowLeftLargeSVG from "../../../../public/assets/images/arrow-left-large.svg";
import ArrowRightLargeSVG from "../../../../public/assets/images/arrow-right-large.svg";
import SushiSVG from "../../../../public/assets/images/sushi.svg";

const { API, BREAKPOINTS, STORAGE, FEATURE_FLAGS } = CONSTANTS;
const { PRODUCTS_PER_CATEGORY_REQUEST, CATEGORIES_PER_REQUEST } = API;
const { tablet } = BREAKPOINTS;
const { CATEGORY_TOKEN } = STORAGE;
const { WITH_BOTTOM_SEARCH, WITH_BOTTOM_RESPONSIVE_BUTTONS } = FEATURE_FLAGS;

const Home: React.FC<Props> = props => {
  const t = useI18n();
  const { width } = useWindowSize();
  const { selectedCatalogueId: catalogueId } = useCatalogues();
  const { defaultStore } = useStores();
  const { storeId } = defaultStore ?? {};
  const { selectedVendorId } = useVendors();
  const fetchCategories = useFetchCategories(notify);
  const auth = useAuth();
  const { data: user } = useFetchUser(auth);
  const isNeededChange = width <= tablet;
  const bannerCategory = isNeededChange
    ? "home_principal_banner_responsive"
    : "home_banner";
  const { name } = user ?? {};
  const { isAnonymous } = auth;
  const greetingText = useMemo(() => {
    if (isAnonymous) return t.profile.welcome.toUpperCase();
    if (!name) return t.profile.greeting;
    return `${t.profile.greeting}, ${name}`;
  }, [isAnonymous, name, t]);
  const categoryResponse = useFetchCategoriesWithProducts(
    {
      catalogueId,
      storeId: storeId!,
      page: 1,
      vendorId: selectedVendorId,
      size: CATEGORIES_PER_REQUEST,
      productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST
    },
    undefined,
    true,
    notify
  );
  const { data: ordersDone } = useFetchOrderHistory({
    auth,
    catalogueId,
    category: "DONE",
    notify
  });
  const dispatchedOrders = ordersDone?.filter(
    order => order.stepCode === "DISPATCHED"
  );
  const thereAreOrdersDone = dispatchedOrders && dispatchedOrders?.length > 0;
  const [lastDispatchedOrder] = dispatchedOrders ?? [];
  const { id: lastDispatchedOrderId } = lastDispatchedOrder ?? {};
  const isMobile = width <= tablet;
  const { data: categoriesWithImages = [] } = fetchCategories;
  const { isLoading: isLoadingCategories } = fetchCategories;
  const { data: categoriesPages } = categoryResponse;
  const { isLoading: isLoadingProductGallery } = categoryResponse;
  const mainCategory = useMemo(() => {
    const allCategories = categoriesPages?.pages.flatMap(page => page.data);
    const featured = allCategories?.find(category => category.featured);
    if (!featured) return;
    return {
      ...featured,
      products: featured?.products?.slice(0, 4),
      name: toSentenceCaseUtility(featured?.name ?? "")
    };
  }, [categoriesPages]);
  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const { pathname } = window.location;
    const en = pathname.split("/")[1];

    setIsEnglish(en === "en");
  }, []);

  /**
   * Render product gallery component.
   *
   * @since 1.0.0
   * @return Product gallery component.
   */
  const renderProductGallery = () => {
    if (isLoadingProductGallery) return <ProductGalleryPlaceholder />;
    if (!mainCategory) return null;
    return (
      <ProductGallery
        className="Home__product-gallery"
        category={mainCategory}
      />
    );
  };

  /**
   * Executed in banner click event
   *
   * @param {Category} category Category data.
   */
  const clickHandler = async (category: Category) => {
    const { categoryId } = category;
    try {
      await Storage.set({
        key: CATEGORY_TOKEN,
        value: JSON.stringify(categoryId)
      });
    } catch (e) {
      console.error("Cannot save category in storage");
    }
    goToCategory(category);
  };

  return (
    <Styles
      className="Home"
      isAnonymous={isAnonymous}
      isEnglish={isEnglish}
      withBottomSearch={WITH_BOTTOM_SEARCH}
      withBottomResponsiveButtons={WITH_BOTTOM_RESPONSIVE_BUTTONS}
    >
      <header className="Home__header">
        <Navbar showBrand />
      </header>
      <Carousel
        className="Home__carousel"
        bannerCategoryName={bannerCategory}
        infinite
      />
      <div className="Home__carousel-pre-wrapper" />
      <main className="Home__main">
        <div className="Home__greetings__container">
          <p className="Home__greetings__text">{greetingText}</p>
          {!isAnonymous ? <SushiSVG /> : null}
        </div>
        {!isAnonymous && thereAreOrdersDone ? (
          <RepeatOrder
            className="Home__repeat-order"
            lastOrderDoneId={lastDispatchedOrderId}
          />
        ) : null}
        <CategorySlider
          height={isMobile ? 208 : 488}
          title={t.common.whatDoYouProvokeToday}
          className="Home__category-slider"
          prevArrow={<ArrowLeftLargeSVG />}
          nextArrow={<ArrowRightLargeSVG />}
          isLoading={isLoadingCategories}
          arrows
        >
          {categoriesWithImages.map(category => {
            const { name, images } = category;
            return (
              <SliderCard
                name={toSentenceCaseUtility(name)}
                images={images}
                arrowText=""
                onClick={() => clickHandler(category)}
                imageConfig={
                  isMobile ? homeSliderImageConfigMobile : homeSliderImageConfig
                }
                key={category.categoryId}
              />
            );
          })}
        </CategorySlider>
        {renderProductGallery()}
        <HomeCards />
      </main>
      <footer className="Home__footer">
        <Footer />
      </footer>
    </Styles>
  );
};

Home.defaultProps = {};

export default Home;
