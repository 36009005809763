import styled from "styled-components";

import { CarouselStyledProps as Props } from "./Carousel.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop } = BREAKPOINTS;

const CarouselStyled = styled.div<Props>`
  .Pagination {
    padding-bottom: 1.2rem;
  }

  .Carousel {
    &__categories {
      background-color: var(--palette-black-h60-s25-l2-a10);
    }

    &__container {
      z-index: 0;
    }

    &__counter {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--palette-gray-s0-l40-a40);
      width: 3.6rem;
      height: 2.4rem;
      color: var(--palette-white);
      position: absolute;
      top: ${props => (props.withCategoryMenu ? "18rem" : "12rem")};
      right: 2.4rem;
      border-radius: 10rem;
      z-index: 1;
      font-size: 1.4rem;
      line-height: 1.6rem;

      @media (max-width: ${desktop}px) {
        top: 20rem;
        right: 1.6rem;
      }
    }
  }
`;

export default CarouselStyled;
