import styled from "styled-components";

import { SliderCardStyledProps as Props } from "./SliderCard.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const SliderCardStyled = styled.div<Props>`
  position: relative;
  cursor: pointer;

  .SliderCard {
    &__image {
      border-radius: 1.4rem;

      @media (min-width: ${tablet}px) {
        width: 98%;
        height: 46.8rem;
      }

      .Image__img {
        object-fit: cover;
      }
    }

    &__content {
      position: absolute;
      top: 10%;
      left: 4%;
      right: 0;
      bottom: 0;

      /* display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem; */

      &-badge {
        max-width: 50%;
        padding: 0.4rem 0;
        text-align: center;
        color: var(--palette-text-white);
        border-radius: 2.4rem;
        background-color: var(--palette-gray-s0-l20-a70);
      }

      &-bottom {
        margin-top: auto;
      }

      &-title {
        line-height: 5.6rem;
        font-size: 1.98rem;
        text-transform: uppercase;
        transform: rotate(-9.47deg);
        color: var(--palette-text-white);
        text-shadow: 0 -1.2rem 0 var(--palette-gray-s0-l100);
        font-weight: 700;
        letter-spacing: -0.2rem;
        overflow-wrap: break-word;

        @media (min-width: ${tablet}px) {
          text-shadow: 0 -3.2rem 0 var(--palette-gray-s0-l100);
          font-size: 4rem;
        }
      }

      &-title:before {
        content: attr(data-text);
        overflow-wrap: break-word;
        color: var(--palette-gray-s0-l100);
        position: absolute;
        top: 4rem;

        @media (max-width: ${tablet}px) {
          top: 1.6rem;
        }
      }

      &-button {
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--palette-white);
        background-color: transparent;
        border: 0;
        text-shadow: 0 0 0.2rem var(--palette-black);

        &:hover:not([disabled]) {
          color: var(--palette-gray-s0-l92);
        }
      }

      &-svg {
        margin-left: 2rem;
      }
    }
  }
`;

export default SliderCardStyled;
