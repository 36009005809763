/* istanbul ignore file */
// Home helper functions and data

export const homeSliderImageConfig = {
  width: 368,
  height: 488
};

export const homeSliderImageConfigMobile = {
  width: 158,
  height: 209
};
