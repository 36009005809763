import { useRouter } from "next/router";
import React from "react";

import Styles from "./HomeCards.styles";
import { HomeCardsProps as Props } from "./HomeCards.types";
import Button from "components/global/Button/Button";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const HomeCards: React.FC<Props> = () => {
  const t = useI18n();
  const { isAnonymous } = useAuth();
  const { push } = useRouter();

  /**
   * Function to handle the click on the coupons button.
   *
   * @since 1.0.0
   */
  const onClickCoupons = () => {
    if (isAnonymous) {
      push("/signin");
      return;
    }

    push("/profile/coupons");
  };

  return (
    <Styles className="HomeCards">
      {/* <div className="HomeCards__card HomeCards__card--bottom">
        <div className="HomeCards__content">
          <div className="HomeCards__text">
            <h3 className="HomeCards__title">{t.home.card.download}</h3>
            <h3 className="HomeCards__title">{t.home.card.ourApp}</h3>
          </div>
          <Button className="HomeCards__download-app-button HomeCards__download-app-button--inactive">
            {t.home.card.downloadNow}
          </Button>
        </div>
      </div> */}
      <div className="HomeCards__card">
        <div className="HomeCards__content">
          <div className="HomeCards__text">
            <h3 className="HomeCards__title">{t.home.card.enjoy}</h3>
            <h3 className="HomeCards__title">{t.home.card.discounts}</h3>
            <h3 className="HomeCards__title">{t.home.card.specials}</h3>
          </div>
          <Button
            className="HomeCards__download-app-button HomeCards__download-app-button--secondary"
            onClick={onClickCoupons}
          >
            {t.home.card.coupons}
          </Button>
        </div>
      </div>
      {/* // TODO: re-add card if new design is presented */}
      {/* <div className="HomeCards__card">
        <div className="HomeCards__content">
          <div className="HomeCards__text">
            <h3 className="HomeCards__title">{t.home.card.useYour}</h3>
            <h3 className="HomeCards__title">{t.home.card.couponsOf}</h3>
            <h3 className="HomeCards__title">{t.home.card.discount}</h3>
          </div>
          <Button
            className="HomeCards__download-app-button HomeCards__download-app-button"
            onClick={onClickCoupons}
          >
            {t.home.card.coupons}
          </Button>
        </div>
      </div> */}
    </Styles>
  );
};

HomeCards.defaultProps = {};

export default HomeCards;
