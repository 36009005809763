import styled from "styled-components";

import { HomeCardsStyledProps as Props } from "./HomeCards.types";
import CONSTANTS from "config/constants";
const { BREAKPOINTS } = CONSTANTS;

const { tablet, mobile } = BREAKPOINTS;

const HomeCardsStyled = styled.div<Props>`
  display: flex;
  margin-top: 8rem;
  margin-bottom: 6.4rem;
  gap: 1.6rem;
  justify-content: center;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
    margin-top: 2.4rem;
    margin-bottom: 4rem;
  }

  .HomeCards {
    &__card {
      width: 40rem;
      min-height: 32rem;
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      border-radius: 2rem;
      /* TODO: return to primary if second card is shown */
      background-color: var(--palette-black-s7-l14);
      overflow: hidden;
      /* TODO: return to 1 if second card is shown */
      background-image: url("/assets/images/static-banner-02.png");
      background-repeat: no-repeat;
      background-position: right;

      &--bottom {
        background-position: bottom right;
      }

      @media (max-width: ${tablet}px) {
        flex-direction: column;
        width: 100%;
      }

      :nth-child(2) {
        background-color: var(--palette-black-s7-l14);
        background-image: url("/assets/images/static-banner-02.png");
      }

      :nth-child(3) {
        background-color: var(--palette-primary);
        background-image: url("/assets/images/static-banner-03.png");
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      padding: 1.6rem;
      padding-top: 3.6rem;
    }

    &__text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      max-width: 33.6rem;
      display: flex;
      font-weight: 700;
      font-size: 3.6rem;
      color: var(--palette-white);
      text-transform: uppercase;
      text-align: left;
      letter-spacing: -0.082rem;
      line-height: 100.6%;

      @media (max-width: ${mobile}px) {
        max-width: 20.6rem;
      }
    }

    &__download-app-button {
      opacity: 1;
      cursor: pointer;
      padding: 1.2rem 2rem;
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 800;
      border-radius: 2.4rem;

      width: 20rem;
      background-color: var(--palette-black-s7-l14) !important;
      color: var(--palette-white);

      &--secondary {
        background-color: var(--palette-primary) !important;
        color: var(--palette-white);
      }

      &--inactive {
        opacity: 0.6;
      }
    }
  }
`;

export default HomeCardsStyled;
